define("ember-place-autocomplete/components/place-autocomplete-field", ["exports", "@ember/component", "ember-place-autocomplete/components/place-autocomplete-field", "@ember/array", "@ember/utils", "@ember/runloop", "@ember/service", "@ember/object", "@ember/application", "@ember/template-factory"], function (_exports, _component, _placeAutocompleteField, _array, _utils, _runloop, _service, _object, _application, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (has-block)}}
    {{yield this}}
  {{else}}
    <input
      value={{@value}}
      name={{@name}}
      tabindex={{@tabindex}}
      disabled={{@disabled}}
      placeholder={{@placeholder}}
      class={{@inputClass}}
      {{on 'blur' this.onBlur}}
      {{on 'input' (action (mut @value) value='target.value')}}
    >
  {{/if}}
  
  */
  {
    "id": "tmizec3c",
    "block": "[[[41,[48,[30,7]],[[[1,\"  \"],[18,7,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"input\"],[16,2,[30,1]],[16,3,[30,2]],[16,\"tabindex\",[30,3]],[16,\"disabled\",[30,4]],[16,\"placeholder\",[30,5]],[16,0,[30,6]],[4,[38,4],[\"blur\",[30,0,[\"onBlur\"]]],null],[4,[38,4],[\"input\",[28,[37,5],[[30,0],[28,[37,6],[[30,1]],null]],[[\"value\"],[\"target.value\"]]]],null],[12],[13],[1,\"\\n\"]],[]]]],[\"@value\",\"@name\",\"@tabindex\",\"@disabled\",\"@placeholder\",\"@inputClass\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"input\",\"on\",\"action\",\"mut\"]]",
    "moduleName": "ember-place-autocomplete/components/place-autocomplete-field.hbs",
    "isStrictMode": false
  });
  const RETRY_WINDOW = 100;
  let PlaceAutocompleteFieldComponent = _exports.default = (_dec = (0, _service.inject)('google-place-autocomplete/manager'), _dec2 = (0, _object.computed)('google'), _dec3 = (0, _object.computed)('isGoogleAvailable'), (_class = class PlaceAutocompleteFieldComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "placeAutocompleteManagerService", _descriptor, this);
    }
    get isGoogleAvailable() {
      return !!this.google;
    }
    get isGoogleMapsAvailable() {
      return this.isGoogleAvailable && this.google.maps;
    }

    /* HOOKS
    ---------------------------------------------------------------------------*/
    /**
     * Set default values in component init
     */
    init() {
      super.init(...arguments);
      this._applyDefaults();
      const owner = (0, _application.getOwner)(this);
      const google = owner.lookup('google:main');
      const navigator = owner.lookup('navigator:main');
      this.setProperties({
        google,
        navigator
      });
    }

    /**
     * @description Initialize component after is has been added to the DOM
     */
    didInsertElement() {
      this._super(...arguments);
      this._bindDataAttributesToInput();
      this.setupComponent();
      this.get('placeAutocompleteManagerService').register();
    }

    /**
     * @description Clean up component before removing it from the DOM
     */
    willDestroy() {
      if ((0, _utils.isPresent)(this.autocomplete)) {
        const google = this.google;
        this.get('placeAutocompleteManagerService').unregister();
        if (google && google.maps && google.maps.event) {
          google.maps.event.clearInstanceListeners(this.autocomplete);
          this.get('placeAutocompleteManagerService').removePlacesAutoCompleteContainersIfRequired();
        }
      }
    }

    /**
     * @description Acts as an observer an updates the autocomplete instance with any
     * updated options that have been passed into the component.
     */
    didReceiveAttrs() {
      if (this.autocomplete) {
        this.autocomplete.setOptions(this.getOptions());
      }
    }

    /**
     * @description Returns an object containing any options that are
     * to be passed to the autocomplete instance.
     * @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
     */
    getOptions() {
      const google = this.google;
      const options = {
        types: this._typesToArray()
      };
      if (this.latLngBnds && Object.keys(this.latLngBnds).length === 2) {
        const {
          sw,
          ne
        } = this.latLngBnds;
        options.bounds = new google.maps.LatLngBounds(sw, ne);
      }
      if (this.restrictions && Object.keys(this.restrictions).length > 0) {
        options.componentRestrictions = this.restrictions;
      }
      if (this.fields) {
        options.fields = this._fieldsToArray();
      } else if (this.placeIdOnly) {
        options.fields = ['place_id', 'name', 'types'];
      }
      return options;
    }

    // Wait until the google library is loaded by calling this method
    // every 100ms
    setupComponent() {
      if (document && this.isGoogleAvailable && this.isGoogleMapsAvailable) {
        this.setAutocomplete();
        if (this.withGeoLocate) {
          this.geolocateAndSetBounds();
        }
        this.autocomplete.addListener('place_changed', () => {
          this.placeChanged();
        });
      } else {
        if (!this.isDestroyed && !this.isDestroying) {
          (0, _runloop.later)(this, 'setupComponent', RETRY_WINDOW);
        }
      }
    }
    keyDown(e) {
      if (this.preventSubmit && (0, _utils.isEqual)(e.keyCode, 13)) {
        e.preventDefault();
      }
    }
    setAutocomplete() {
      if ((0, _utils.isEmpty)(this.autocomplete)) {
        const inputElement = document.getElementById(this.elementId).getElementsByTagName('input')[0],
          google = this.google || window.google; //TODO: check how to use the inyected google object

        let autocomplete = new google.maps.places.Autocomplete(inputElement, this.getOptions());
        this.set('autocomplete', autocomplete);
      }
    }

    /**
     * @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
     */
    geolocateAndSetBounds() {
      const {
        autocomplete
      } = this;
      if (this.navigator && this.navigator.geolocation && (0, _utils.isPresent)(autocomplete)) {
        this.navigator.geolocation.getCurrentPosition(position => {
          const google = this.google;
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          const circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });
          autocomplete.setBounds(circle.getBounds());
        });
      }
    }
    placeChanged() {
      let place = this.autocomplete.getPlace();
      this._callCallback('placeChangedCallback', place);
      if (place[this.setValueWithProperty] !== undefined) {
        this.set('value', place[this.setValueWithProperty]);
      }
    }
    _callCallback(callback, place) {
      let callbackFn = this.get(callback);
      if ((0, _utils.isEqual)((0, _utils.typeOf)(callbackFn), 'function')) {
        callbackFn(place);
      }
      return this.bubbles ? true : false;
    }
    _toArray(value) {
      if ((0, _array.isArray)(value)) {
        return value;
      } else if ((0, _utils.typeOf)(value) === 'string') {
        if (value.trim() === '') {
          return [];
        } else {
          return value.split(',');
        }
      } else {
        return [];
      }
    }
    _typesToArray() {
      return this._toArray(this.types);
    }
    _fieldsToArray() {
      return this._toArray(this.fields);
    }
    _applyDefaults() {
      const defaultProperties = {
        layout: _placeAutocompleteField.default,
        disabled: false,
        inputClass: 'place-autocomplete--input',
        types: undefined,
        restrictions: {},
        tabindex: 0,
        withGeoLocate: false,
        setValueWithProperty: undefined,
        preventSubmit: false,
        placeIdOnly: false
      };
      for (let property in defaultProperties) {
        if ((0, _utils.isBlank)(this.get(property))) {
          this.set(property, defaultProperties[property]);
        }
      }
    }
    _bindDataAttributesToInput() {
      if (!window || !document) {
        return false;
      }
      const componentProperties = Object.keys(this) || [];
      const properties = componentProperties.filter(prop => prop.indexOf('data-') >= 0);
      const input = document.getElementById(this.elementId).getElementsByTagName('input')[0];
      properties.forEach(property => input.setAttribute(property, this.get(property)));
      return true;
    }
    onBlur(event) {
      this._callCallback('onBlurCallback', event.target.value);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "placeAutocompleteManagerService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isGoogleAvailable", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isGoogleAvailable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isGoogleMapsAvailable", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isGoogleMapsAvailable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBlur", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onBlur"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PlaceAutocompleteFieldComponent);
});